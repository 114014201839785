// Add previousPath to location to enable custom routing
export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer];

  if (window.locations[window.locations.length - 1] !== window.location.href) {
    window.locations.push(window.location.href);
  }

  window.previousPath = window.locations[window.locations.length - 2];
};

// Redirect legacy hashbang URLs from my old website
export const onClientEntry = () => {
  const href = window.location.href;
  const hashes = href.match(/#!\/(.*)/);
  const hash = hashes ? hashes[1] : undefined;
  const cleanHash = hash ? hash.split(/\?|&/, 1)[0] : undefined;

  if (cleanHash) {
    const paths = ['project', 'curriculum', 'contact', 'blog', 'imprint'];
    var path = paths.find(function (path) {
      return cleanHash.indexOf(path) !== -1;
    });

    if (path && path === 'project') {
      const oldProjectPath = cleanHash.split('project/')[1];
      const newProjectPath = getProjectPath(oldProjectPath);

      if (newProjectPath) {
        window.location.replace('/' + getSitePath(path) + '/' + newProjectPath);
      }
    } else if (path) {
      window.location.replace('/' + getSitePath(path));
    }
  }
};

const getSitePath = (oldPath: string) => {
  // prettier-ignore
  const siteMap = [
    { old: 'project', new: 'projekte' },
    { old: 'curriculum', new: 'vita' },
    { old: 'contact', new: 'kontakt' },
    { old: 'blog', new: 'blog' },
    { old: 'imprint', new: 'impressum' },
  ];

  const sitePath = siteMap.find((sitePath) => sitePath.old === oldPath);

  return sitePath ? (sitePath.new ? sitePath.new : sitePath.old) : undefined;
};

const getProjectPath = (oldProjectPath: string) => {
  // prettier-ignore
  const projectMap = [
    { old: 'elite-hacker-des-fsb', new: 'elite-hacker-fsb' },
    { old: 'klima-dashboard' },
    { old: 'urbane-hitzeinseln', new: 'hitzeinseln' },
    { old: 'bundestagswahl-merkel-nach-zahlen', new: 'merkel-nach-zahlen-2021' },
    { old: 'automatische-corona-grafiken', new: 'corona-grafiken' },
    { old: 'ki-bewerbung' },
    { old: 'corona-impfrechner' },
    { old: 'corona-reproduktionszahl' },
    { old: 'corona-testzahlen' },
    { old: 'corona-rueckblick' },
    { old: 'corona-meldepannen' },
    { old: 'corona-faq' },
    { old: 'corona-newsletter' },
    { old: 'corona-simulation' },
    { old: 'defekte-aufzuege' },
    { old: 'datenleck-bei-patientendaten' },
    { old: 'hackergruppe-winnti' },
    { old: 'schwierige-datenschutzerklaerungen' },
    { old: 'eu-nach-zahlen' },
    { old: 'eu-urheberrechtsreform' },
    { old: 'dreckige-ernte' },
    { old: 'pestizidzulassungen' },
    { old: 'cumex-files' },
    { old: 'black-friday' },
    { old: 'repraesentativitaet-des-landtags' },
    { old: 'honorarkonsuln' },
    { old: 'aerztemangel' },
    { old: 'durchschnittliche-parlamentarier' },
    { old: 'preiswahnsinn-bei-grafikkarten' },
    { old: 'obike-datenleck' },
    { old: 'workshop-d3-und-leaflet' },
    { old: 'kabinett-o-mat' },
    { old: 'korrelationen-der-wahlergebnisse' },
    { old: 'fake-follower-der-parteien' },
    { old: 'analyse-der-wahlprogramme' },
    { old: 'diskriminierung-auf-dem-mietmarkt' },
    { old: 'merkel-nach-zahlen' },
    { old: 'angriff-auf-die-demokratie' },
    { old: 'madeira-offshore' },
    { old: 'madeira-search' },
    { old: 'praesidentschaftswahlen-in-den-usa' },
    { old: 'milliarden-fuer-syrien' },
    { old: 'rentenrechner' },
    { old: 'rio-de-janeiro-gewalt-und-spiele' },
    { old: 'em-2016-geburtsorte-der-spieler' },
    { old: 'entwicklung-der-milchpreise' },
    { old: 'ladensterben-in-bayern' },
    { old: 'bayern-crystal-meth-breitet-sich-aus' },
    { old: 'twitter-analyse-des-tatorts' },
    { old: 'deutsche-wahlkampfspenden-in-den-usa' },
    { old: 'sie-haben-gewonnen-gewinnspielbetrug' },
    { old: 'klimawandel-in-den-bayerischen-alpen' },
    { old: 'asylgrossauftraege-wer-von-den-fluechtlingen-profitiert' },
    { old: 'asylgrossauftraege-twitter-bot' },
    { old: 'heimvorteil-woher-stammt-die-liga' },
    { old: 'heimvorteil-bayerische-nationalmannschaft' },
    { old: 'game-of-thrones-netzwerk' },
    { old: 'karte-gleichgeschlechtliche-ehe' },
    { old: 'bosnien-kinder-des-krieges' },
    { old: 'automatisierung-wann-werde-ich-ersetzt' },
    { old: 'waehlerwanderung-hamburg' },
    { old: 'chronik-boko-haram' },
    { old: 'wm-2014-der-weg-zum-titel' },
    { old: 'serious-game-organspenden' },
    { old: 'google-standortverlauf-visualisieren' },
    { old: 'eu-kommission' },
    { old: 'rausch-hoch-fliegen-tief-fallen' },
    { old: 'das-verschwinden-der-fdp' },
    { old: 'brazillion-world-cup-billions' },
    { old: '25-jahre-tiananmen-massaker' },
    { old: 'europawahl' },
    { old: 'newsgame-world-cup-simulation' },
    { old: 'prototyp-sueddeutsche-zeitung' },
    { old: 'nebeneinkuenfte-bundestag' },
    { old: 'kartendesign-blossom' },
    { old: 'sueddeutsche-zeitung-app' },
    { old: 'geheimer-krieg' },
    { old: 'rechte-gewalt-in-bayern' },
    { old: 'bundestagswahl' },
    { old: 'zugmonitor-auswertung' },
    { old: 'regionen-in-bayern' },
    { old: 'verschaetzung-der-welt' },
    { old: 'begleiter' },
    { old: 'zensus-2011' },
    { old: 'interaktives-bahnhofsinformationssystem' },
    { old: 'vampyre-unter-uns' },
    { old: 'nippon-nuklear' },
  ];

  const projectPath = projectMap.find(
    (projectPath) => projectPath.old === oldProjectPath
  );

  return projectPath
    ? projectPath.new
      ? projectPath.new
      : projectPath.old
    : undefined;
};
